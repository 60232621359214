<template>
  <div id="calculator">
    <div class="container">
      <div class="row justify-content-center mt20">
        <div class="col-12">
          <!-- Header -->
          <div class="header">
            <div class="header-body">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h1 class="header-title">
                    投入产出计算器
                    <a-popover placement="top" class="tips">
                      <template slot="content">
                        <p>本计算器计算结果仅供参考</p>
                      </template>
                      <a-button>i</a-button>
                    </a-popover>
                  </h1>
                  <!-- Pretitle -->
                  <h6 class="header-pretitle">Calculator</h6>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->

      <div class="card">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">当前网络状态</h4>
        </div>
        <div class="card-body">
          <div class="row gx-5">
            <div class="col-lg-3">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h5 class="text-uppercase text-muted mb-2">全网有效算力</h5>

                  <!-- Heading -->
                  <span class="h2 mb-0"
                    >{{
                      afterLoading && article.total_quality_power | totalQP
                    }}
                    EiB</span
                  >
                </div>

                <div class="d-lg-none">
                  <hr />
                </div>
              </div>
              <!-- / .row -->
            </div>

            <div class="col-lg-3 column-divider-lg">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h5 class="text-uppercase text-muted mb-2">
                    24h平均挖矿收益
                  </h5>

                  <!-- Heading -->
                  <span class="h2 mb-0"
                    >{{
                      afterLoading && article.fil_per_tera | numFilter
                    }}
                    FIL/TiB</span
                  >
                </div>

                <div class="d-lg-none">
                  <hr />
                </div>
              </div>
              <!-- / .row -->
            </div>

            <div class="col-lg-3 column-divider-lg">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h5 class="text-uppercase text-muted mb-2">当前扇区质押量</h5>

                  <!-- Heading -->
                  <span class="h2 mb-0"
                    >{{
                      afterLoading && article.payment_32 | numFilter
                    }}
                    FIL/32GiB</span
                  >
                </div>
              </div>
              <div class="d-lg-none">
                <hr />
              </div>
              <!-- / .row -->
            </div>
            <div class="col-lg-3 column-divider-lg">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h5 class="text-uppercase text-muted mb-2">全网算力增速</h5>

                  <!-- Heading -->
                  <span class="h2 mb-0">{{ increasePower || "" }} PiB/天</span>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt20">
        <div class="col-12 col-xl-3">
          <div class="card bg-light border border-info">
            <div class="card-body">
              <!-- Form -->
              <form>
                <div class="form-group">
                  <label for="power" class="form-label">有效算力</label>
                  <div class="input-group input-group-merge mb-3">
                    <input
                      class="form-control"
                      @change.prevent="calc"
                      v-model="power"
                      id="power"
                      placeholder="有效算力"
                      type="text"
                      aria-label="有效算力"
                      aria-describedby="powerUnit"
                    />
                    <div class="input-group-text" id="powerUnit">TiB</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="cost" class="form-label">投入成本</label>
                  <div class="input-group input-group-merge mb-3">
                    <input
                      class="form-control"
                      @change.prevent="calc"
                      v-model="cost"
                      id="cost"
                      placeholder="投入成本"
                      type="text"
                      aria-label="投入成本"
                      aria-describedby="costUnit"
                    />
                    <div class="input-group-text" id="costUnit">RMB</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="price" class="form-label">FIL币价</label>
                  <div class="input-group input-group-merge mb-3">
                    <input
                      class="form-control"
                      @change.prevent="calc"
                      v-model="price"
                      id="price"
                      placeholder="FIL币价"
                      type="text"
                      aria-label="FIL币价"
                      aria-describedby="priceUnit"
                    />
                    <div class="input-group-text" id="priceUnit">USDT</div>
                  </div>
                </div>

                <!-- Button -->
                <button @click.prevent="calc" class="btn btn-info">
                  开始计算
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-9">
          <div class="card border border-info">
            <div class="table-responsive">
              <table class="table table-nowrap card-table text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th v-if="recordList.records['win_day']">
                      <span
                        v-if="
                          recordList.records['win_day'].days[0] !== 180 &&
                          recordList.records['win_day'].days[0] !== 360 &&
                          recordList.records['win_day'].days[0] !== 540
                        "
                        >产出平衡</span
                      ><span v-else>挖矿</span><br />{{
                        recordList.records["win_day"].days[0]
                      }}天
                    </th>
                    <th>
                      <span
                        v-if="
                          recordList.records[180].days[0] !== 180 &&
                          recordList.records[180].days[0] !== 360 &&
                          recordList.records[180].days[0] !== 540
                        "
                        >产出平衡</span
                      ><span v-else>挖矿</span><br />{{
                        recordList.records[180].days[0]
                      }}天
                    </th>
                    <th>
                      <span
                        v-if="
                          recordList.records[360].days[0] !== 180 &&
                          recordList.records[360].days[0] !== 360 &&
                          recordList.records[360].days[0] !== 540
                        "
                        >产出平衡</span
                      ><span v-else>挖矿</span><br />{{
                        recordList.records[360].days[0]
                      }}天
                    </th>
                    <th>
                      <span
                        v-if="
                          recordList.records[540].days[0] !== 180 &&
                          recordList.records[540].days[0] !== 360 &&
                          recordList.records[540].days[0] !== 540
                        "
                        >产出平衡</span
                      ><span v-else>挖矿</span><br />{{
                        recordList.records[540].days[0]
                      }}天
                    </th>
                  </tr>
                </thead>
                <tbody class="font-size-base">
                  <tr>
                    <td>区块奖励总产出</td>
                    <td v-if="recordList.records['win_day']">
                      {{
                        Math.floor(recordList.records["win_day"].reward[0][1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records["win_day"].reward[0][1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{ Math.floor(recordList.records[180].reward[1]) }} FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[180].reward[1] * price * usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{ Math.floor(recordList.records[360].reward[1]) }} FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[360].reward[1] * price * usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{ Math.floor(recordList.records[540].reward[1]) }} FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[540].reward[1] * price * usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>已释放区块奖励</td>
                    <td v-if="recordList.records['win_day']">
                      {{
                        Math.floor(
                          recordList.records["win_day"].release_reward[0][1]
                        )
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records["win_day"].release_reward[0][1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[180].release_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[180].release_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[360].release_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[360].release_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[540].release_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[540].release_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>待释放区块奖励</td>
                    <td v-if="recordList.records['win_day']">
                      {{
                        Math.floor(
                          recordList.records["win_day"].unrelease_reward[0][1]
                        )
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records["win_day"]
                              .unrelease_reward[0][1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[180].unrelease_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[180].unrelease_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[360].unrelease_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[360].unrelease_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                    <td>
                      {{
                        Math.floor(recordList.records[540].unrelease_reward[1])
                      }}
                      FIL
                      <div class="text-muted">
                        ≈
                        {{
                          Math.floor(
                            recordList.records[540].unrelease_reward[1] *
                              price *
                              usdt
                          )
                        }}
                        RMB
                      </div>
                    </td>
                  </tr>
                  <tr class="bg-info text-white">
                    <td>扣除投入成本后产出</td>
                    <td v-if="recordList.records['win_day']">
                      ≈
                      {{
                        Math.floor(
                          recordList.records["win_day"].reward[0][1] *
                            price *
                            usdt -
                            cost
                        )
                      }}
                      RMB
                    </td>
                    <td>
                      ≈
                      {{
                        Math.floor(
                          recordList.records[180].reward[1] * price * usdt -
                            cost
                        )
                      }}
                      RMB
                    </td>
                    <td>
                      ≈
                      {{
                        Math.floor(
                          recordList.records[360].reward[1] * price * usdt -
                            cost
                        )
                      }}
                      RMB
                    </td>
                    <td>
                      ≈
                      {{
                        Math.floor(
                          recordList.records[540].reward[1] * price * usdt -
                            cost
                        )
                      }}
                      RMB
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { Popover } from "ant-design-vue";
Vue.use(Popover);

export default {
  name: "calculator",
  asyncData: async ({ store }) => {
    let [request1Data, request2Data, request3Data] = [
      await store.dispatch("GET_ARTICLE", { type: "32G" }),
      await store.dispatch("GET_COIN"),
      await store.dispatch("GET_INCREASE_POWER"),
    ];
    return {
      data1: request1Data,
      data2: request2Data,
      data3: request3Data,
    };
  },
  computed: {
    article: {
      get() {
        return this.$store.state.article;
      },
      set(val) {
        this.$store.state.article = val;
      },
    },
    priceData: {
      get() {
        return this.$store.state.coin;
      },
      set(val) {
        this.$store.state.coin = val;
      },
    },
    increaseData: {
      get() {
        return this.$store.state.increase_power;
      },
      set(val) {
        this.$store.state.increase_power = val;
      },
    },
  },
  data() {
    return {
      afterLoading: false,
      power: "10000",
      cost: "20000000",
      price: "",
      records: [
        {
          create_cost_gas_per_t: "",
          create_gas: "",
          date: "",
          day: 121,
          keep_cost_gas_per_t: "",
          keep_gas: "",
          package_days: 0,
          pledge_per_day: "",
          release_days: 0,
          reward_per_day: "",
          today_new_power: "",
          today_pledge: "",
          today_power: "",
          today_release: "",
          today_release_principal: "",
          today_reward: "",
          today_reward_base: "",
          total_already_release: "",
          total_create_gas: "",
          total_keep_gas: "",
          total_pledge: "",
          total_release: "",
          total_release_principal: "",
          total_reward: "",
        },
        {
          create_cost_gas_per_t: "",
          create_gas: "",
          date: "",
          day: 121,
          keep_cost_gas_per_t: "",
          keep_gas: "",
          package_days: 0,
          pledge_per_day: "",
          release_days: 0,
          reward_per_day: "",
          today_new_power: "",
          today_pledge: "",
          today_power: "",
          today_release: "",
          today_release_principal: "",
          today_reward: "",
          today_reward_base: "",
          total_already_release: "",
          total_create_gas: "",
          total_keep_gas: "",
          total_pledge: "",
          total_release: "",
          total_release_principal: "",
          total_reward: "",
        },
        {
          create_cost_gas_per_t: "",
          create_gas: "",
          date: "",
          day: 121,
          keep_cost_gas_per_t: "",
          keep_gas: "",
          package_days: 0,
          pledge_per_day: "",
          release_days: 0,
          reward_per_day: "",
          today_new_power: "",
          today_pledge: "",
          today_power: "",
          today_release: "",
          today_release_principal: "",
          today_reward: "",
          today_reward_base: "",
          total_already_release: "",
          total_create_gas: "",
          total_keep_gas: "",
          total_pledge: "",
          total_release: "",
          total_release_principal: "",
          total_reward: "",
        },
        {
          create_cost_gas_per_t: "",
          create_gas: "",
          date: "",
          day: 121,
          keep_cost_gas_per_t: "",
          keep_gas: "",
          package_days: 0,
          pledge_per_day: "",
          release_days: 0,
          reward_per_day: "",
          today_new_power: "",
          today_pledge: "",
          today_power: "",
          today_release: "",
          today_release_principal: "",
          today_reward: "",
          today_reward_base: "",
          total_already_release: "",
          total_create_gas: "",
          total_keep_gas: "",
          total_pledge: "",
          total_release: "",
          total_release_principal: "",
          total_reward: "",
        },
      ],
      recordList: {
        full_day: 0,
        records: {
          180: {
            create_gas: "0.0000",
            days: [180],
            keep_gas: ["794.5559"],
            keep_gas: ["794.5559"],
            pledge: "0.0000",
            release_reward: ["121629.7013", "134473.7500"],
            reward: ["190599.5551", "216360.0000"],
            unrelease_reward: ["68969.8538", "81886.2500"],
          },
          360: {
            create_gas: "0.0000",
            days: [360],
            keep_gas: ["794.5559"],
            pledge: "0.0000",
            release_reward: ["121629.7013", "134473.7500"],
            reward: ["190599.5551", "216360.0000"],
            unrelease_reward: ["68969.8538", "81886.2500"],
          },
          540: {
            create_gas: "0.0000",
            days: [540],
            keep_gas: ["794.5559"],
            keep_gas: ["794.5559"],
            pledge: "0.0000",
            release_reward: ["121629.7013", "134473.7500"],
            reward: ["190599.5551", "216360.0000"],
            unrelease_reward: ["68969.8538", "81886.2500"],
          },
          win_day: {
            create_gas: "0.0000",
            days: [110, 120],
            keep_gas: ["485.5619", "529.7039"],
            pledge: "0.0000",
            release_reward: [
              ["58904.7503", "62779.4583"],
              ["66751.7100", "71519.0000"],
            ],
            reward: [
              ["122565.7633", "132220.0000"],
              ["132759.2861", "144240.0000"],
            ],
            unrelease_reward: [
              ["63661.0130", "69440.5417"],
              ["66007.5761", "72721.0000"],
            ],
          },
        },
        win_day: 110,
      },
      usdt: 6.55, //误差小
      nowData: new Date().toLocaleDateString(),
      increasePower: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.afterLoading = true;
      this.price = this.priceData[0].quote.USD.price.toFixed(2);
      this.increasePower = this.increaseData[0].increase_power_per_day;
      this.calc();
    });
  },
  filters: {
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(3);
      } else {
        realVal = "--";
      }
      return realVal;
    },
    totalQP(value) {
      let result = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        value = value / (1024 * 1024 * 1024 * 1024 * 1024 * 1024);
        result = parseFloat(value).toFixed(2);
      } else {
        result = "--";
      }
      return result;
    },
  },
  methods: {
    calc() {
      axios
        .post(`${this.$originHost}/api/v1/countInputOutput`, {
          cost: this.cost,
          increase_power_per_day: this.increasePower, //算力增速
          init_power: this.power,
          total_power: this.power,
        })
        .then((response) => {
          if (response.status == 200 && response.data.code == 0) {
            this.recordList = response.data.data;
          }
        });
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
@import "../../assets/css/theme.css";
#calculator {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-variant: tabular-nums;
  .header {
    border-bottom: 1px solid #e3ebf6;
    .header-body {
      padding-top: 1rem;
      border: 0;
    }
  }
  .header-title {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 2rem;
    .tips {
      display: inline-block;
      width: 15px;
      height: 15px;
      line-height: 15px;
      font-size: 1.2rem;
      font-style: normal;
      border-radius: 50%;
      text-align: center;
      background-color: #ddd;
      color: #fff;
    }
  }
  .header-pretitle {
    text-transform: initial;
    font-size: 1.4rem;
    color: #95aac9;
  }
  .row {
    margin-left: 0;
  }
  .card {
    .card-header {
      h4 {
        flex: 1;
        text-align: left;
        font-size: 1.3rem;
        color: #12263f;
        margin-bottom: 0;
      }
    }
    .card-body {
      .h2 {
        display: block;
        margin-top: 1rem;
        font-size: 1.6rem;
      }
    }
  }
  .input-group-merge {
    input {
      box-sizing: content-box;
    }
  }
  .table-responsive {
    margin-bottom: 0;
    th {
      border-bottom: 1px solid #eee;
    }
    td {
      vertical-align: middle;
    }
  }
}
.container {
  max-width: 1400px;
}
.ant-popover-inner-content {
  p {
    margin: 5px 0;
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 768px) {
}
</style>
<style>
.navbar.navbar-dark .fgNavItem {
  width: 100%;
  padding:0;
  /* padding-right: 15px;
  padding-left: 15px; */
}
.navbar-toggler {
  padding: 0.25rem 0.75rem !important;
  font-size: 1.25rem !important;
  line-height: 1 !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 0.25rem !important;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.fgNavItem #nav-collapse {
  width: calc(100% + 2rem) !important;
  margin-left: 0;
  padding: 0;
}
.fgNavItem .navbar-brand {
  transform: unset;
}
</style>
